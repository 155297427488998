<script>
  import { page } from '@inertiajs/svelte'
  import { Icon, ArrowPath, Plus, ExclamationCircle } from 'svelte-hero-icons'
  import WeekdayTaskContainer from './components/WeekdayTaskContainer.svelte'
  import ObjectiveForm from './components/ObjectiveForm.svelte'
  import Modal from '@/components/Modal.svelte'
  import { userStore } from '@/stores/UserStore'
  import { citizenStore } from '@/stores/CitizenStore'
  import { boardStore } from '@/stores/TaskBoardStore'
  import { modalStore as taskModal } from '@/stores/ModalStore'
  import { objectiveFormStore } from '@/stores/ObjectiveFormStore'
  import { citizenFlagMessageFormStore } from '@/stores/CitizenFlagMessageFormStore'
  import { taskForm } from '@/stores/TaskFormStore'
  import axios from 'axios'
  import AvatarUploadForm from '../../Users/AvatarUploadForm.svelte'
  import CitizenFlagMessageForm from './components/CitizenFlagMessageForm.svelte'
  import Task from '../../Tasks/components/Task.svelte'
  import TaskForm from '../../Tasks/components/TaskForm.svelte'
  import {
    taskChannelStore,
    subscribeToTaskChannel,
  } from '@/channels/TaskChannel'

  export let citizen = null
  export let recurring_tasks = []
  export let users = []
  export let citizens = []
  export let objectives = []
  export let flag_messages = []

  let showNewTaskModal = false

  $userStore = users
  $citizenStore = citizens
  $citizenStore.objectives = objectives
  $citizenStore.flag_messages = flag_messages
  $boardStore.tasks = recurring_tasks

  subscribeToTaskChannel($page.props.auth.company.slug)
  $: if ($taskChannelStore.action === 'task_update') {
    loadRecurringTasks()
    taskChannelStore.reset()
    showNewTaskModal = false
  }

  function openNewTaskModal() {
    $boardStore.selectedTask = null
    taskForm.reset()
    $taskForm.citizen_id = citizen.id
    showNewTaskModal = true
  }

  function loadRecurringTasks() {
    axios
      .get(`/floweffect/citizens/${citizen.id}/recurring_tasks`)
      .then((res) => {
        $boardStore.tasks = res.data
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function deactivateCitizen() {
    axios
      .delete(`/floweffect/citizens/${citizen.id}/`)
      .then(() => {
        window.location = '/floweffect/citizens'
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function closeModal() {
    showNewTaskModal = false
  }
</script>

<div class="citizen-header-container">
  <div class="avatar">
    <AvatarUploadForm user={citizen} type="citizen" />
    <h1 style="margin-top: 6rem;">{citizen.name}</h1>
  </div>
  <div class="actions">
    <button
      class="btn btn-danger"
      on:click={() => {
        deactivateCitizen()
      }}
    >
      Deaktiver borger
    </button>
  </div>
</div>

<div class="container">
  <div class="task-container-header">
    <div class="repeating-tasks-title">Gentagne opgaver</div>
    <button
      class="btn btn-default btn-icon"
      on:click={() => {
        openNewTaskModal()
      }}
    >
      <Icon src={Plus} size="16" />
      Tilføj opgave
    </button>
  </div>

  <div class="weekdays-container" style="margin-top: 2rem;">
    <WeekdayTaskContainer day={'Mandag'} tasks={$boardStore.tasks['monday']} />
    <WeekdayTaskContainer
      day={'Tirsdag'}
      tasks={$boardStore.tasks['tuesday']}
    />
    <WeekdayTaskContainer
      day={'Onsdag'}
      tasks={$boardStore.tasks['wednesday']}
    />
    <WeekdayTaskContainer
      day={'Torsdag'}
      tasks={$boardStore.tasks['thursday']}
    />
    <WeekdayTaskContainer day={'Fredag'} tasks={$boardStore.tasks['friday']} />
    <WeekdayTaskContainer
      day={'Lørdag'}
      tasks={$boardStore.tasks['saturday']}
    />
    <WeekdayTaskContainer day={'Søndag'} tasks={$boardStore.tasks['sunday']} />
  </div>
</div>

<div class="container" style="margin-top: 50px">
  <div class="task-container-header">
    <h2>OBS Punkter</h2>
    <button
      class="btn btn-default btn-icon"
      on:click={() => {
        $citizenFlagMessageFormStore.showModal = true
      }}
    >
      <Icon src={Plus} size="16" />
      Tilføj OBS punkt
    </button>
  </div>
  <div class="objective-container">
    {#each $citizenStore.flag_messages as flag_message}
      <div class="card">
        <div class="card-body">
          <div class="card-text flag-message-container">
            <div class="flag-message-icon">
              <Icon src={ExclamationCircle} size="20" />
            </div>
            <div>{flag_message.message}</div>
          </div>
        </div>
      </div>
    {/each}
  </div>
</div>

<div class="container" style="margin-top: 50px">
  <div class="task-container-header">
    <h2>Udviklingsmål</h2>
    <button
      class="btn btn-default btn-icon"
      on:click={() => {
        $objectiveFormStore.showModal = true
      }}
    >
      <Icon src={Plus} size="16" />
      Tilføj mål
    </button>
  </div>
  <div class="objective-container">
    {#each $citizenStore.objectives as objective}
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">{objective.title}</h4>
          <div class="card-text">{objective.description}</div>
        </div>
      </div>
    {/each}
  </div>
</div>

<Modal bind:showModal={showNewTaskModal}>
  <span slot="header">Opret ny opgave</span>

  <TaskForm {closeModal} {citizen} />
</Modal>

<Modal bind:showModal={$taskModal.showModal}>
  <div slot="header">
    <div>
      Opgave: {$boardStore.selectedTask.name}
      {#if $boardStore.selectedTask.recurring}
        <span class="badge badge-pill badge-primary">
          <Icon src={ArrowPath} size="12" />
          Gentagende</span
        >
      {/if}
    </div>
  </div>

  <Task showCompleteButton={false} />
</Modal>

<Modal bind:showModal={$boardStore.showTaskModal}>
  <div slot="header">
    <div>
      Opgave: {$boardStore.selectedTask.name}
      {#if $boardStore.selectedTask.recurring}
        <span class="badge badge-pill badge-primary">
          <Icon src={ArrowPath} size="12" />
          Gentagende</span
        >
      {/if}
    </div>
  </div>

  <Task />
</Modal>

<Modal bind:showModal={$objectiveFormStore.showModal}>
  <span slot="header">Opret nyt mål</span>
  <ObjectiveForm {citizen} />
</Modal>

<Modal bind:showModal={$citizenFlagMessageFormStore.showModal}>
  <span slot="header">Opret nyt OBS punkt</span>
  <CitizenFlagMessageForm {citizen} />
</Modal>

<style>
  .objective-container {
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
    gap: 20px;
  }

  .objective-container > .card {
    width: 30%;
    margin-bottom: 20px;
  }

  .repeating-tasks-title {
    font-size: 36px;
  }

  .container {
    margin-top: 20px;
  }

  .citizen-header-container {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
  }

  .task-container-header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .weekdays-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .flag-message-container {
    display: flex;
    gap: 10px;
  }

  .flag-message-icon {
    color: #fe9f5b;
    font-weight: bold;
  }
</style>
