<script>
  import CitizenCalendar from '../../Tasks/components/CitizenCalendar.svelte'
  import CitizenFlagMessages from '../../Tasks/components/CitizenFlagMessages.svelte'
  import CitizenObjectives from '../../Tasks/components/CitizenObjectives.svelte'
  import BoardContainer from '@/components/FlowEffect/BoardContainer.svelte'

  export let citizen = null
  export let users = []
  export let flag_messages = []
  export let objectives = []
</script>

<BoardContainer>
  <div style="padding-left: 50px; padding-right: 50px;">
    <div class="col-xs-12 col-lg-8">
      <CitizenCalendar {citizen} {users} />
    </div>
    <!-- TODO: Consider renaming citizen param -->
    <div class="col-xs-12 col-lg-4">
      <CitizenFlagMessages {citizen} flags={flag_messages} />
      <CitizenObjectives {citizen} {objectives} />
    </div>
  </div>
</BoardContainer>
