<script>
  import { onMount } from 'svelte'
  import {
    Icon,
    PlusCircle,
    XCircle,
    CheckCircle,
    ArrowPath,
  } from 'svelte-hero-icons'
  import Modal from '@/components/Modal.svelte'
  import TaskForm from '../../pages/Tasks/components/TaskForm.svelte'
  import Task from '@/pages/Tasks/components/Task.svelte'
  import CheckInModal from '@/pages/Tasks/components/CheckInModal.svelte'
  import TaskGrabbingUserModal from '@/pages/Tasks/components/TaskGrabbingUserModal.svelte'
  import { userStore } from '@/stores/UserStore'
  import { boardStore, reloadTasks } from '@/stores/TaskBoardStore'
  import { loadTasks } from '@/stores/TaskStore'
  import { modalStore as userGrabbingModalStore } from '@/stores/ModalStore'
  import axios from 'axios'
  import dayjs from 'dayjs'

  export let showActionButtons = true

  let showNewTaskModal = false
  let showCheckInModal = false

  function resetBoardTimers() {
    setInterval(() => {
      boardStore.resetScrollTime()
    }, 1_200_000) // 20 minute

    setInterval(() => {
      $boardStore.startTime = dayjs().startOf('day')
      $boardStore.endTime = dayjs().endOf('day')
    }, 3_600_000) // 1 hour

    setInterval(() => {
      if (dayjs().format('HH:mm') === '05:00') {
        window.location.reload()
      }
    }, 60_000) // 1 min
  }

  function handleTaskGrabbingButtonClick() {
    if ($boardStore.taskGrabbingActivated) {
      postGrabbedTasks()
    } else {
      userGrabbingModalStore.openModal()
    }
  }

  function postGrabbedTasks() {
    let grabbedTasksData = { tasks: { user_id: null, task_data: [] } }
    $boardStore.grabbedTasks.forEach((taskData) => {
      grabbedTasksData.tasks.task_data.push({
        id: taskData.id,
        start_time: taskData.start_time,
        end_time: taskData.end_time,
      })
    })

    grabbedTasksData.tasks.user_id = $boardStore.userGrabbingTasks.id

    axios
      .post('/floweffect/tasks/grab_tasks', grabbedTasksData)
      .then(() => {
        boardStore.resetGrabbedTasks()
        loadTasks($boardStore.startTime, $boardStore.endTime)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  function closeModal() {
    showNewTaskModal = false
  }

  onMount(() => {
    resetBoardTimers()
  })
</script>

<!-- Content -->
<slot />

<!-- Green corner buttons -->
{#if showActionButtons}
  <div class="corner-btns">
    {#if true}
      <button
        class="corner-btn green-corner-btn"
        on:click={() => {
          handleTaskGrabbingButtonClick()
        }}
      >
        {#if $boardStore.grabbedTasks.length > 0}
          <Icon src={CheckCircle} size="36" />
          Gem {$boardStore.grabbedTasks.length} opgaver
        {:else}
          <Icon src={PlusCircle} size="36" />
          Tag opgaver
        {/if}
      </button>
    {/if}
    {#if $boardStore.taskGrabbingActivated}
      <button
        class="corner-btn red-corner-btn"
        on:click={() => {
          $boardStore.taskGrabbingActivated = false
          boardStore.resetGrabbedTasks()
          reloadTasks()
        }}
      >
        <Icon src={XCircle} size="36" />
        Annuller
      </button>
    {/if}
    <button
      class="corner-btn green-corner-btn new-task-btn"
      on:click={() => {
        showNewTaskModal = !showNewTaskModal
      }}
    >
      <Icon src={PlusCircle} size="36" />
      Ny opgave
    </button>
    <button
      class="corner-btn green-corner-btn check-in-btn"
      on:click={() => {
        showCheckInModal = !showCheckInModal
      }}
    >
      <Icon src={CheckCircle} size="36" />
      Tjek ind / ud
    </button>
  </div>
{/if}

<!-- Modals -->
{#if showActionButtons}
  <Modal bind:showModal={showNewTaskModal}>
    <span slot="header">Opret ny opgave</span>

    <TaskForm {closeModal} />
  </Modal>

  <Modal bind:showModal={showCheckInModal}>
    <span slot="header">Tjek ind / ud</span>

    <CheckInModal users={$userStore} bind:showModal={showCheckInModal} />
  </Modal>

  <Modal bind:showModal={$userGrabbingModalStore.showModal}>
    <span slot="header">Hvem skal sættes på opgaverne?</span>
    <TaskGrabbingUserModal />
  </Modal>
{/if}

<Modal bind:showModal={$boardStore.showTaskModal}>
  <div slot="header">
    <div>
      Opgave: {$boardStore.selectedTask.name}
      {#if $boardStore.selectedTask.recurring}
        <span class="badge badge-pill badge-primary">
          <Icon src={ArrowPath} size="12" />
          Gentagende</span
        >
      {/if}
    </div>
  </div>

  <Task />
</Modal>

<style>
  .corner-btn {
    z-index: 100;
    border: none;
    background-color: #64cc7c;
    color: white;
    padding: 10px 10px 10px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 20px;
  }

  .green-corner-btn:hover,
  .green-corner-btn:active {
    background-color: #4e9e5d;
  }

  .corner-btns {
    display: flex;
    gap: 1rem;
    position: fixed;
    bottom: 5rem;
    right: 5rem;
    z-index: 100;
  }

  .red-corner-btn {
    background-color: #f92121;
  }
</style>
