<script>
  import { onMount, onDestroy } from 'svelte'
  import { boardStore } from '@/stores/TaskBoardStore'
  import { tasks } from '@/stores/TaskStore'
  import { userStore } from '@/stores/UserStore'
  import { checkedInUserStore } from '@/stores/CheckedInUserStore'
  import axios from 'axios'
  import UserAvatar from '@/components/UserAvatar.svelte'
  import Button from '@/components/Button.svelte'
  import UserCheckInOptions from './UserCheckInOptions.svelte'

  let userSelected = null
  let checkIns = []
  let loading = true

  onMount(() => {
    $boardStore.safeToReload = false
  })

  onDestroy(() => {
    $boardStore.safeToReload = true
  })

  function resetUser() {
    userSelected = null
  }

  function updateCheckIns() {
    axios
      .get(
        '/check_in_logs' +
          `?start_time=${$boardStore.startTime}&end_time=${$boardStore.endTime}`,
      )
      .then((response) => {
        checkIns = response.data
        loading = false
        checkedInUserStore.loadCheckedInUsers()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  function getCheckInStatus(user) {
    let checkIn = getCheckIn(user)

    let userTasks = $tasks.find((task) => {
      return task.user_id == user.id && task.completed_at == null
    })

    if (checkIn) {
      if (checkIn.check_out_time) {
        if (userTasks) {
          return 'warning'
        } else {
          return 'default'
        }
      } else {
        return 'success'
      }
    } else if (userTasks) {
      return 'warning'
    } else {
      return 'default'
    }
  }

  function statusText(user) {
    let checkIn = getCheckIn(user)

    let userTasks = $tasks.find((task) => {
      return task.user_id == user.id && task.completed_at == null
    })

    if (checkIn) {
      if (checkIn.check_out_time) {
        if (userTasks) {
          return 'Taget hjem, har opgaver'
        } else {
          return 'Taget hjem'
        }
      } else {
        return 'På arbejde'
      }
    } else if (userTasks) {
      return 'Ikke på job, har opgaver'
    } else {
      return 'Ikke på job'
    }
  }

  function getCheckIn(user) {
    return checkIns.find((checkIn) => {
      return checkIn.user_id == user.id
    })
  }

  function handleUserClick(user) {
    let checkIn = getCheckIn(user)

    if (!checkIn || (checkIn && !checkIn.check_out_time)) {
      userSelected = user
    }
  }

  updateCheckIns()
</script>

{#key $checkedInUserStore}
  <div>
    <div class="user-box-container">
      {#if loading}
        <div>Loading...</div>
      {:else if userSelected != null}
        <UserCheckInOptions
          user={userSelected}
          checkInLog={getCheckIn(userSelected)}
          {resetUser}
          on:updateCheckIns={updateCheckIns}
        />
      {:else}
        {#each $userStore as user}
          {#key $tasks}
            <Button variant={getCheckInStatus(user)} width={'250px'}>
              <div
                class="user-box-button"
                on:click={() => {
                  handleUserClick(user)
                }}
              >
                <UserAvatar {user} />
                <div class="text-left">
                  <div>{user.name}</div>
                  <small>{statusText(user)}</small>
                </div>
              </div>
            </Button>
          {/key}
        {/each}
      {/if}
    </div>
  </div>
{/key}

<style>
  .user-box-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .user-box-button {
    display: flex;
    align-items: center;
    gap: 10px;
  }
</style>
